@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap);
@font-face {
  font-family: jannatR;
  src: url(/static/media/alfont_com_Janna-LT-Bold.979ee04b.ttf);
}
body {
  margin: 0;
  font-family: jannatR, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.userAvatar{
    display:inline-block;
    margin-left:20px;
}
.noti-icon{
    vertical-align: middle;
    padding-left:  10px;
    padding-right:  5px;
}
.profileSmall{
    display: none!important;
}
.profileLarge{
    display: inline-block;
}
.controlLarge{
    display:  inline-block;
}
.moreSmall{
    display: none;
    margin-right: 10px;
}
@media only screen and (max-width: 600px) {
    .header{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
    }
    .ant-layout-header {
        padding: 0 10px!important;
    }
    .logo{
        width: 60px !important; ;
        
    }
    .avatarName{
        display: none;
    }
    .userAvatar{
        
        margin-left:10px;
    }
    .noti-icon svg{
        width: 25px;
        height: 25px;
    }
    .profileSmall{
        display: inline-block;
    }
    .profileLarge{
        display: none!important;
    }
    .controlLarge{
        display: none!important;
    }
    .moreSmall{
        display: inline-block;
      
    }
}
.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 4px;
  }
  .space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .taggedInfo{
      margin-top: 10px;
      font-size: 15px;
  }

  .site-layout-card .ant-card-body{
    padding-bottom: 6px!important;
  }

  [class="ant-tabs-tab"] a{
    color:black;
  }
  [class="ant-tabs-tab"] a:hover{
    color:#40a9ff;
  }
  .spider>div{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.profile-row{
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
.userData{
  text-align: center;
}
.userProfile{
  display: none;
}
.site-layout-card .ant-card-body{
  padding: 0;
  margin: 0px!important;
}
.site-layout-card{
  margin: 0px 0px!important;
  margin-top: 20px!important; 
  margin-bottom: 10px!important; 
}
.profile-tabs .ant-tabs-nav{
  margin: 0;
}
.profile-tabs{
  padding-right: 20px;
}
.profile-row{
  margin-top: 0px;
}
  }
.apexcharts-legend-marker, .apexcharts-tooltip-marker{
    margin-left: 5px!important;
}
.apexcharts-legend-text{
font-family: jannatR!important;
}
.summary .ant-card-body{
padding: 2px;
}
.userProfileSummary{
    display: none;
}
@media only screen and (max-width: 600px) {
    .pie-chart .apexcharts-canvas{
      width: auto!important;
    }
    .pieColumn{
        width: 100%;
        margin-bottom: 20px;
    }
    .userProfileSummary{
        display: block;
    }
    .ant-card.userProfileSummary{
       margin: 10px 0px!important;
    }
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}

@media print{
    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
}
.generalHeader{
    display:flex;
    flex-direction:row;
    margin-bottom:20px;
    justify-content:space-between;
}
.generalHeader .generalData{
    display:flex;
    flex:1 1;
    flex-direction:row;
    justify-content:space-between;
}
.generalHeader .generalOper{
    display:flex;
    flex-wrap: wrap;

    flex:1 1;
    flex-direction:row;
    justify-content:flex-end;
}
@media only screen and (max-width: 600px) {

    .generalHeader{
        flex-direction:column;
    }
    .generalHeader .generalData{
        order: 2;
        margin-top: 10px;
     }
     .generalHeader .generalOper{
        justify-content:end;
    }
    .generalHeader .generalOper .date {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }

      .generalHeader .generalOper span {
        flex: 0 0 auto; /* Let label take the necessary space */
        margin-right: 10px; /* Add spacing between label and input */
      }
      
      .generalHeader .generalOper .ant-picker{
        flex: 1 1; /* Make input take up remaining space */
      }

    .generalHeader .generalOper .date{
        flex-basis: 100%;
        order: 2;
    }
    .genTable .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        padding: 16px 0px;
    }
}
@media only screen and (max-width: 600px) {

.transHeader{
    display: flex;
    flex-direction: column;
}
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}
.attendance .ant-table-cell,.att-model .ant-table-cell{
    text-align: center!important;
    font-weight: 700;
}
.table-info{
    display: table-header-group;
}
div.header{
position: fixed;
top:0;
}
@media print{
    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
    .table-info{
        position: fixed;
    }
}
.attOper{
    float:left;
    margin-bottom:10px;
    display: flex;
    flex-direction: row;
}
.attHeader{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:30px;
}
.attPer{
    display:flex;
    flex-direction:row;
}
.disPer{
    display:flex;
    flex-direction:row;  
}
@media only screen and (max-width: 600px) {

.attOper{
    order:1;
    display: flex;
    flex-direction: column;
}


.attOperBtn{
    padding-top: 14px;
}
.attHeader{
    flex-direction:column;
}
.attPer{
order: 2;
margin-bottom: 15px;
}
.attOperRange{
   
    margin-top: 15px;
}
.disPer{
    order: 3; 
}
}

@page { size: A4 landscape; print-color-adjust: exact !important;  -webkit-print-color-adjust: exact !important;}
#att-report tbody tr:nth-child(2n){
    border-bottom: 2px solid;
}

@media print{
    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
}
.tasksHeader{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:20px;
}
.tasksOper{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
}
.tasksData{
    display:flex;
    flex-direction:row;
}
.tasksBtn{
    display:flex;
    flex-direction:row; 
}
@media only screen and (max-width: 600px) {

    .tasksHeader{
        flex-direction:column;
    }
    .tasksOper{
        order: 1;
        flex-direction: column;
    }
    .tasksData{
        order: 2;
    }
    .tasksBtn{
        flex-direction:row; 
        order: 2;
        justify-content: flex-end;
    }
    .tasksRange{
        order: 2;
        margin-bottom: 10px;
    }
}

td,th{
    font-size: 12px;
}
.requestsHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.requestsRange{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.reauestsProgress{
    display: flex;
}

@media only screen and (max-width: 600px) {
    .requestsHeader{
        flex-direction: column;
    }
    .requestsRange{
        order:1;
        align-items: end;
        flex-direction: column;
        align-items: flex-start;
    }
    .reauestsProgress{
        order: 2;
    }
    .requestsBtn{
        width: 100%;
    }
}
th{
    text-align: center!important;
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
.discountRange{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
        flex-direction: column;
    }
    .discountBtn{
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 10px;
        }
    .addbtn{
        text-align: left;
        margin-top:10px;
    }
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}

@media print{
    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
}
th{
    text-align: center!important;
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
.discountRange{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
        flex-direction: column;
    }
    .discountBtn{
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 10px;
        }
    .addbtn{
        text-align: left;
        margin-top:10px;
    }
}
th{
    text-align: center!important;
}
.delete-btn:hover{
background-color: #f00!important;
color:#fff!important;
border-radius: 32px;
}
.edit-btn:hover{
    background-color: #0972B6!important;
    color:#fff!important;
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
padding-right: 5px;
}
.groupTasks{
    display:flex;
    flex-direction:row;
}
.usersNames{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap:20px;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-top: 6px;
        order: 2;
        }
        .groupTasks{
            flex-direction:column;
        }
        .usersNames{
            grid-template-columns: repeat(1, 1fr)
        }
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}
.attendance .ant-table-cell,.att-model .ant-table-cell{
    text-align: center!important;
    font-weight: 700;
}
.table-info{
    display: table-header-group;
}
div.header{
position: fixed;
top:0;
}
@media print{
    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
    .table-info{
        position: fixed;
    }
}
.attOper{
    float:left;
    margin-bottom:10px;
    display: flex;
    flex-direction: row;
}
.attHeader{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:30px;
}
.attPer{
    display:flex;
    flex-direction:row;
}
.disPer{
    display:flex;
    flex-direction:row;  
}
@media only screen and (max-width: 600px) {

.attOper{
    order:1;
    display: flex;
    flex-direction: column;
}


.attOperBtn{
    padding-top: 14px;
}
.attHeader{
    flex-direction:column;
}
.attPer{
order: 2;
margin-bottom: 15px;
}
.attOperRange{
   
    margin-top: 15px;
}
.disPer{
    order: 3; 
}
}

@page { size: A4 landscape; print-color-adjust: exact !important;  -webkit-print-color-adjust: exact !important;}
.site-layout{
height: 100%;
}

.site-layout .site-layout-background {
    background: #fff;
  }
  
.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    float: left;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #0972B6;
  }
  .control-drawer{
    display: none;
  }
  .drawer-vis{
    display: none;
    margin-top: 15px;
    text-align: right;
    margin-right: 10px;
  }
  @media only screen and (max-width: 600px) {
  .control-menu{
    display: none;
  }
  .control-drawer{
    display: block;
  }
  .drawer-vis{
    display: inline-block;
  }
  .control-drawer .ant-drawer-body{
    padding: 0;
  }
}
.stat-layout{
    padding:2.75rem 2.25rem;
}
@media only screen and (max-width: 600px) {
    .stat-layout{
        padding: 10px;
        padding-top: 20px;
    }

}
.content .ant-card-body{
    padding:10px;
}

.footer .ant-card-body{
    padding:15px 12px;
}
.ant-badge-status-dot{
    width: 10px;
    height: 10px;
}
.emp-modal .ant-form-item{
margin-bottom: 20px;
}
.emp-modal .ant-modal-body{
padding: 0px;
}
.personal-data .ant-form-item{
 display: flex;
 flex-direction: row;
}
.emp-report-modal *{
    font-family: 'jannatR';
}
.emp-report-modal .dawam-section{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}
.emp-report-modal .ant-progress-circle .ant-progress-text{
color: #fff!important;
}
.emp-report-modal .dawam-section.stat{
    display: flex;
    flex-direction: row;
}
.emp-report-modal .dawam-section>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.emp-report-modal .dawam-section .card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border:1px solid #e8e8e8;
    color: #fff;
}
.emp-report-modal .dawam-section .card-hours{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}
.emp-report-modal .vio-table{
    font-size: 14px;
}
.emp-report-modal .vio-table tr:nth-child(odd) {background-color: #f2f2f2;}
.emp-report-modal .vio-table td:first-child { text-align: right;padding-right: 5px; }
.emp-report-modal .vio-table td:last-child { text-align: left; }
.emp-report-modal .vio-table td{
    font-size: 14px; 
    padding: 8px 5px;
}
.personal-data .ant-form-item-label{
    flex:1 1;
    text-align: left;
    overflow: inherit;
}
.personal-data .ant-form-item-control{
    flex:2 1;
}
.personal-data .ant-form-item-row{
    width: 100%;
}
.card-col{
    margin-bottom:'-10px';
}
@media only screen and (max-width: 600px) {

.addBtn{
display: none;
}
.emp-report-modal .dawam-section{
    flex-direction: column;
}
.card-col{
    margin: 5px 0px;
}
}


.content .ant-card-body{
    padding:10px;
}

.footer .ant-card-body{
    padding:15px 12px;
}
.ant-badge-status-dot{
    width: 10px;
    height: 10px;
}
table{
    text-align: center!important;
}
.ant-table-column-sorters-with-tooltip{
    text-align: center;
}
td{
    width: auto!important;
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}


    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
    .discountHeader{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .discountBtn{
    display: flex;
    flex-direction: row;
    }
    @media only screen and (max-width: 600px) {
    
        .discountHeader{
            flex-direction: column;
        }
        .discountRange{
            order: 2;
        }
        .discountBtn{
            justify-content: flex-end;
            margin-bottom: 10px;
            }
    }

    td, th {
        page-break-inside: avoid !important;
    }

    @media print {
        td, th,tr {page-break-inside: avoid;}
      }
table{
    text-align: center!important;
}
.ant-table-column-sorters-with-tooltip{
    text-align: center;
}
td{
    width: auto!important;
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}


    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}
.attOper{
    float:left;
    margin-bottom:10px;
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .attOper{
        order:1;
        display: flex;
        flex-direction: column;
    }

    .attOperBtn{
        padding-top: 14px;
    }
    .attHeader{
        flex-direction:column;
    }
    .attPer{
    order: 2;
    margin-bottom: 15px;
    }
    .attOperRange{
       
        margin-top: 15px;
    }
    .disPer{
        order: 3; 
    }
    }
th{
    text-align: center!important;
}
table{
    text-align: center!important;
}
.ant-table-column-sorters-with-tooltip{
    text-align: center;
}
td{
    width: auto!important;
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}


    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
    .discountHeader{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .discountBtn{
    display: flex;
    flex-direction: row;
    }
    @media only screen and (max-width: 600px) {
    
        .discountHeader{
            flex-direction: column;
        }
        .discountRange{
            order: 2;
        }
        .discountBtn{
            justify-content: flex-end;
            margin-bottom: 10px;
            }
    }

.gSettingForm label{
    text-align: left;
   }
.gSettingForm input{
 width: 200px;
}
.gSettingForm .ant-form-item-label {
width: 150px;
justify-content: left;
}

.gSettingForm label{
    text-align: left;
   }
.gSettingForm input{
 width: 200px;
}
.gSettingForm .ant-form-item-label {
width: 150px;
justify-content: left;
}
th{
    text-align: center!important;
}
.delete-btn:hover{
background-color: #f00!important;
color:#fff!important;
border-radius: 32px;
}
.edit-btn:hover{
    background-color: #0972B6!important;
    color:#fff!important;
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
padding-right: 5px;
}
.groupTasks{
    display:flex;
    flex-direction:row;
}
.usersNames{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap:20px;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
        .groupTasks{
            flex-direction:column;
        }
        .usersNames{
            grid-template-columns: repeat(1, 1fr)
        }
}
th{
    text-align: center!important;
}
.delete-btn:hover{
background-color: #f00!important;
color:#fff!important;
border-radius: 32px;
}
.edit-btn:hover{
    background-color: #0972B6!important;
    color:#fff!important;
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
padding-right: 5px;
}
.groupTasks{
    display:flex;
    flex-direction:row;
}
.usersNames{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap:20px;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
        .groupTasks{
            flex-direction:column;
        }
        .usersNames{
            grid-template-columns: repeat(1, 1fr)
        }
}


.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
.discountRange{
    margin-left: 10px;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
        margin-top: 10px;
    }
    .discountBtn{
        justify-content: flex-end;
        }
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}
.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}

body{
  background-color: #F3F4F9;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  float: left;
  transition: color 0.3s;
}

.trigger:hover {
  color: #0972B6;
}

.logo {
  float: right;
  width: 100px;
  height: 70px;
  background-image: url(/static/media/logo.6d1b0292.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.site-layout .site-layout-background {
  background: #fff;
}
.menuIcon{
 text-align: right;
}
.ant-layout-header{
  background-color: #fff;
}
.loginParent{
  text-align:right;
  font-family:'jannatR';
  background-color:#F3F4F9;
  padding:8% 10% 0;
}
.loginBox{
  padding:100px 60px 0;
  border-radius:10px;
  box-shadow:0 2px 6px 0 hsla(0,0%,0%,0.2);
  height:500px;
  background-color: #fff;
}
.loginBox>div{
  margin: 0 50px;
}
.formTitle{
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #0972B6;
}
.formTitle img{
  width: 150px;
}
.loginBox input{
  background-color:#ADD0E6;
}
#root{
  height: 100%;
}
.login-btn{
margin-top: 40px;
}
.loginForm{
margin-top: 50px;
}
.illustrateBox{
  display: none;
}
@media only screen and (max-width: 600px) {
  .mainColumn{
    display: none;
  }
  .loginBox{
    padding:10px 30px 20px;
    height: auto;
  }
  .formTitle{

    margin-bottom: 10px;
    margin-top: 10px;
  }
  .formTitle img{
    width: 120px;
  }

  .loginBox>div{
    margin: 0;
  }
  .formColumn{
    max-width: 100%;
    flex: none;
  }
  .formColumn .ant-form-item-label{
    text-align: right;
    padding-bottom: 0px;
  }
  .formColumn .ant-form-item{
    margin-bottom: 0px;
  }
  .login-btn{
    margin-top: 20px;
    }
    .loginForm{
      margin-top: 0px;
      }
      .illustrateBox{
        display: block;
        padding: 20px 10px;
      }
      .loginParent{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
}
